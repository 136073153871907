import {Component} from 'vue-property-decorator'
import {VNode} from 'vue'

import BackendClientMixin from "@/mixins/BackendClientMixin"

import TableComponent from "@/components/TableComponent"

import {authStore} from "@/store"
import {activityStore, PaginationPayload, SortBy} from "../store/activity"

import * as ServiceRoutes from "@/_modules/service/router/routes"
import * as RequestRoutes from "@/_modules/request/router/request-service-routes"
import {BIcon, BIconArrowLeft, BIconArrowRight, BIconPlusCircle, BIconSortDown, BIconSortDownAlt} from "bootstrap-vue";

import {OfferSummary, RequestShortDetails, ServicesSummary, TasksDates} from "@/_modules/service/types"
import {FieldLike, TableRow} from "@/utils/vue-bootstrap-types"
import {formatDate, sortObjectsByDates} from "@/utils/formatters"
import ApplicationConfiguration, {defaultPaginationItemsPerPage} from "@/constants/ApplicationConfiguration"
import {BasicErrorHandler} from "@/utils/errorHandler"
import * as Error from "@/utils/errors"
import {ErrorCodes} from "@/constants/APIconstants"
import {OrderDirection} from "@/types"
import {sortDates, stringifyWithFormat} from "@/utils/string-utils";
import {BadgeEvent, badgesStore} from "@/store/badges-store";
import PageHeader from '@/components/layout/PageHeader'
import ContentSection from '@/components/layout/ContentSection'
import AppIconCaretDown from '@/components/icons/AppIconCaretDown'
import AppIconCaretUp from '@/components/icons/AppIconCaretUp'
import { getDealStatusColor } from '@/components/calendar/mixin'

export class ServicesErrorHandler extends BasicErrorHandler {

  protected async handleBackendError(e: Error.BackendError): Promise<void> {
    switch(e.code) {
      case ErrorCodes.ServiceRequestNotFound:
        this.errorMessageKey = "err_service_request_not_found"
        break
      case ErrorCodes.UserDoesNotHaveProfile:
        this.errorMessageKey = "err_user_does_not_have_profile"
        break
      case ErrorCodes.OperationIsNotAuthorized:
        this.errorMessageKey = "err_operation_is_not_authorized"
        break
      case ErrorCodes.UserDoesNotHaveRightToAccess:
        this.errorMessageKey = "err_user_does_not_have_rights_to_access"
        break
      case ErrorCodes.ServiceNotFound:
        this.errorMessageKey = "err_service_not_found"
        break
      default:
        await super.handleBackendError(e)
        break
    }
  }
}

const servicesErrorHandler = new ServicesErrorHandler()

const datesMaxDisplayedLen = 2

@Component({
  name: 'Services',
  components: {
    BIcon,
    BIconArrowLeft,
    BIconArrowRight,
    BIconPlusCircle,
    BIconSortDown,
    BIconSortDownAlt
  }
})
export default class extends BackendClientMixin {

  public currentPage = 1
  public sortBy = SortBy.date
  public sortMode = OrderDirection.DESC

  public services: ServicesSummary = {
    nr: 0,
    size: 10,
    items: [],
    total: 0
  }

  public get serviceTableFields(): FieldLike[] {
    return [
      {
        key: 'name',
        label: this.translation('title_offer_title')
      },
      {
        key: 'createdAt',
        label: this.translation('title_offer_date'),
        formatter: formatDate
      },
      {
        key: 'requests',
        label: this.translation('title_offer_requests')
      },
      {
        key: 'active',
        label: this.translation('title_offer_status'),
        formatter: this.formatStatus
      },
      {
        key: 'details',
        label: this.translation('title_offer_details'),
        class: 'border-left-0 px-0 app-table-row-collapse'
      }
    ]
  }

  public get requestTableFields(): FieldLike[] {
    return [
      {
        key: 'requesterName',
        label: this.translation('title_requester_name')
      },
      {
        key: 'dates',
        label: this.translation('title_request_date')
      },
      {
        key: 'price',
        label: this.translation('title_request_price'),
        class: 'text-right'
      },
      {
        key: 'status',
        label: this.translation('title_request_status')
      }
    ]
  }

  public async mounted(): Promise<void> {
    await this.fetchServicesList()
  }

  public async fetchServicesList(): Promise<void> {
    const payload: PaginationPayload = {
      userId: authStore.authInfo!.userId!,
      pagination: {
        page: this.services.nr,
        size: this.services.size
      },
      order: {
        sortBy: this.sortBy,
        sortMode: this.sortMode
      }
    }
    this.services = await this.withRequest(activityStore.getServices(payload), servicesErrorHandler)
    this.services.items.map(it => {
      sortObjectsByDates(it.requests)
    })

    this.services.items.map(it => {
      return it.requests.map(iter => {
        return sortDates(iter.tasksDates)
      })
    })
  }

  public formatStatus(value: string | undefined): string {
    return value ? this.translation('enum_offer_active') : this.translation('enum_offer_inactive')
  }

  public async redirectToRequest(item: RequestShortDetails): Promise<void> {
    const badges: BadgeEvent[] = badgesStore.getBadgeEvents
    const result = badges.find(it => it.docId === item.id)
    if (result !== undefined) {
      await this.withRequest(badgesStore.deleteBadgeCall(result.id), servicesErrorHandler).then(() => {
        badgesStore.removeInternalBadgeEvent(result)
      })
    }
    await this.$router.push({
      path: `${RequestRoutes.RequestProvider.path}/${item.id}`
    })
  }

  public renderStatus(serviceRequest: TableRow<RequestShortDetails>): VNode {
    return (
      <div class="d-flex align-items-baseline">
        <span class={`deal-status-indicator bg-${getDealStatusColor(serviceRequest.item.status)} mr-2`} aria-hidden="true" />
        {this.translation(`lbl_status_${serviceRequest.item.status}`)}
        {this.defineRelativeExactBadge(serviceRequest)}
      </div>
    )
  }

  private defineRelativeListBadge(service: TableRow<OfferSummary>): VNode | undefined {
    const badges: BadgeEvent[] = badgesStore.getBadgeEvents
    const result = badges.find(it => {
      return service.item.requests.find(iter => iter.id === it.docId)
    })
    return (
      result !== undefined ? 
        <b-badge
          variant="danger"
          class="badge-event-label"
        >
          {this.translation('lbl_badge_new')}
        </b-badge> : undefined
    )
  }

  private defineRelativeExactBadge(serviceRequest: TableRow<RequestShortDetails>): (VNode | undefined)[] {
    const badges: BadgeEvent[] = badgesStore.getBadgeEvents
    return (
      badges.map((it) => {
        if (serviceRequest.item.id === it.docId) {
          return (
            <b-badge
              variant="danger"
              class="badge-event-label"
            >
              {this.translation('lbl_badge_new')}
            </b-badge>
          )
        } else {
          return undefined
        }
      })
    )
  }

  public render(): VNode {
    return(
      <b-container fluid="xl">
        <b-row>
          <PageHeader
            headerClass="my-10 mt-lg-4"
            title={this.translation('menu_offer')}
            wideHeader={true}
          >
            <p>{this.translation('services_description')}</p>
            <b-button
              variant="primary"
              to={`${ServiceRoutes.ServiceNewFromActivity.path}`}
            >
              <b-icon-plus-circle class="app-icon-lg mr-2" aria-hidden="true" />
              {this.translation("btn_add_service")}
            </b-button>
          </PageHeader>
        </b-row>
        <ContentSection>
          <b-row>
            <b-col cols="12">
              <TableComponent
                bordered={false}
                busy={this.busy}
                class="app-table"
                current-page={this.services.nr-1}
                empty-key="msg_table_empty"
                fields={this.serviceTableFields}
                hover={false}
                id="services-table"
                items={this.services.items}
                per-page={defaultPaginationItemsPerPage}
                responsive="md"
                striped={false}
                thead-class="app-table-thead-bordered"
                scopedSlots={{
                  "head(name)": (row: TableRow<never>) => {
                    return (
                      <div class="d-flex align-items-center">
                        <span class="mr-2">{row.field.label}</span>
                        <b-link
                          class="d-flex text-primary ml-auto"
                          onClick={() => {
                            this.sortMode = this.sortMode === OrderDirection.DESC ? OrderDirection.ASC : OrderDirection.DESC
                            this.sortBy = SortBy.name
                            this.fetchServicesList()
                          }}
                          aria-label={this.translation('table_sort_column')}
                        >
                          <b-icon aria-hidden="true" class="app-icon-lg" icon={(this.sortMode === OrderDirection.ASC && this.sortBy === SortBy.name) || this.sortBy !== SortBy.name ? 'sort-down-alt' : 'sort-down'}/>
                        </b-link>
                      </div>
                    )
                  },
                  "head(createdAt)": (row: TableRow<never>) => {
                    return (
                      <div class="d-flex align-items-center">
                        <span class="mr-2">{row.field.label}</span>
                        <b-link
                          class="d-flex text-primary ml-auto"
                          onClick={() => {
                            this.sortMode = this.sortMode === OrderDirection.DESC ? OrderDirection.ASC : OrderDirection.DESC
                            this.sortBy = SortBy.date
                            this.fetchServicesList()
                          }}
                          aria-label={this.translation('table_sort_column')}
                        >
                          <b-icon aria-hidden="true" class="app-icon-lg" icon={(this.sortMode === OrderDirection.ASC && this.sortBy === SortBy.date) || this.sortBy !== SortBy.date ? 'sort-down-alt' : 'sort-down'}/>
                        </b-link>
                      </div>
                    )
                  },
                  "head(details)": (row: TableRow<never>) => {
                    return (
                      <div class="sr-only">{row.field.label}</div>
                    )
                  },
                  "cell(name)": (service: TableRow<OfferSummary>) => {
                    return (
                      <div class="ellipsis-string-container" v-b-tooltip={{title: service.value.split(' ').join('').length > ApplicationConfiguration.titleCropMidLength ? service.value : '', variant: 'primary'}}>
                        <b-link class={service.detailsShowing ? 'text-white' : 'text-primary'} to={`${ServiceRoutes.ServiceEditFromActivity.path}/${service.item.id}`}>
                          {service.value}
                        </b-link>
                      </div>
                    )
                  },
                  "cell(requests)": (service: TableRow<OfferSummary>) => {
                    return (
                      <div>
                        {service.item.requestsCount > 0 &&
                        <b-link aria-label={this.translation('title_offer_details')} class={service.detailsShowing ? 'text-white' : 'text-primary'} onClick={() => (service.toggleDetails())}>
                          {service.item.requests.length}
                          {this.defineRelativeListBadge(service)}
                        </b-link>
                        }
                        {service.item.requestsCount === 0 &&
                        <span>-</span>
                        }
                      </div>
                    )
                  },
                  "cell(details)": (service: TableRow<OfferSummary>) => {
                    return (
                      <div>
                        {service.item.requestsCount > 0 &&
                        <b-link aria-label={this.translation('title_offer_details')} class={service.detailsShowing ? 'text-white' : 'text-primary'} onClick={() => (service.toggleDetails())}>
                          {!service.detailsShowing &&
                            <AppIconCaretDown class="app-icon-lg" />
                          }
                          {service.detailsShowing &&
                            <AppIconCaretUp class="app-icon-lg" />
                          }
                        </b-link>
                        }
                        {service.item.requestsCount === 0 &&
                        <span></span>
                        }
                      </div>
                    )
                  },
                  "row-details": (service: TableRow<OfferSummary>) => {
                    return (
                      <TableComponent
                        bordered={false}
                        busy={this.busy}
                        empty-key=''
                        fields={this.requestTableFields}
                        hover={true}
                        items={service.item.requests}
                        responsive="md"
                        selectable
                        striped={false}
                        wrapper-class="mb-0"
                        onRowClicked={(item: RequestShortDetails) => {
                          this.redirectToRequest(item)
                        }}
                        scopedSlots={{
                          "cell(requesterName)": (serviceRequest: TableRow<RequestShortDetails>) => {
                            const fullName = `${serviceRequest.item.requesterName.first} ${serviceRequest.item.requesterName.last}`
                            return (
                              <div class="ellipsis-string-container" v-b-tooltip={{title: fullName.split(' ').join('').length > ApplicationConfiguration.nameCropMidLength ? fullName : '', variant: 'primary'}}>
                                <span>{fullName}</span>
                              </div>
                            )
                          },
                          "cell(dates)": (serviceRequest: TableRow<RequestShortDetails>) => {
                            const dates = serviceRequest.item.tasksDates as TasksDates[] as string[]
                            return (
                              <div class="ellipsis-string-container" v-b-tooltip={{title: dates.length > datesMaxDisplayedLen ? stringifyWithFormat(dates) : '', variant: 'primary'}}>
                                <span>{stringifyWithFormat(dates)}</span>
                              </div>
                            )
                          },
                          "cell(price)": (serviceRequest: TableRow<RequestShortDetails>) => {
                            return (
                              <span>{serviceRequest.item.totalPrice.amount} {this.translation(`enum_currency_${serviceRequest.item.totalPrice.currency}`)}</span>
                            )
                          },
                          "cell(status)": (serviceRequest: TableRow<RequestShortDetails>) => {
                            return (
                              <div>
                                {this.renderStatus(serviceRequest)}
                              </div>
                            )
                          }
                        }}
                      />
                    )
                  }
                }}
              />
            </b-col>
            {this.services.total > defaultPaginationItemsPerPage &&
              <b-col cols="12">
                <b-pagination
                  align="center"
                  aria-controls="services-table"
                  class="mt-6 mt-md-10 mb-0"
                  first-number
                  last-number
                  pills
                  per-page={defaultPaginationItemsPerPage}
                  total-rows={this.services.total}
                  value={this.currentPage}
                  onChange={(v: number) => {
                    this.services.nr = v -1
                    this.currentPage = v
                    this.fetchServicesList()
                  }}
                >
                  <template slot="prev-text"><b-icon-arrow-left /></template>
                  <template slot="next-text"><b-icon-arrow-right /></template>
                </b-pagination>
              </b-col>
            }
          </b-row>
        </ContentSection>
      </b-container>
    )
  }
}
